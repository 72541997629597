import { motion } from "framer-motion";
import Logo from "../../images/Logos/star-company-logo.png";
import Bg1 from "../../images/BrandsBackground/star-company.jpg";
import mainPhoto from "../../images/BrandsBackground/star-company-mainPhoto.jpg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import { useTranslation } from "react-i18next";
import {
  arrAnimation,
  branchAnimationLeft,
  branchAnimationRight,
  mainAnimation,
} from "../../animations";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";

export const StarCompany = () => {
  const footerInfo = {
    formURL: "",
    phoneNumber: "+38 (098)-365-50-49",
    email: "hrnadincompany@gmail.com",
    inst: "progress_market_company",
    telega: "hr_Nadezda",
    viber: "0983655049",
    address: "",
  };
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const isDesktop = screenWidth > 1100;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <main>
      <Header mainColor={`#486e40`} imgSrc={Logo} branchRoute="/star-company" />
      <section className="relative h-[75vh]">
        <div
          className="w-full h-full absolute top-0 left-0"
          style={{
            backgroundImage: `url(${mainPhoto})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="container__standart pt-[25vh] flex items-center flex-col"
          >
            <motion.h1
              variants={mainAnimation}
              className="text-center text-white font-bold text-5xl mx-[2rem] mainPageLG:text-6xl"
            >
              {t("StarCompany.name")}
            </motion.h1>
            <button
              className={`transition__hover mt-[6.25rem] bg-[#486e40] text-white px-[10px] h-12 rounded-lg hover:bg-orange-400`}
              onClick={() => {
                dispatch(setActive(true));
              }}
            >
              {t("mainPage.consultation")}
            </button>
          </motion.div>
        </div>
      </section>
      

{/* --------------------------- */}

      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-12"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="text-center md:text-left mb-[1.6rem]"
              >
                {t("StarCompany.block1.h1")}
              </motion.h5>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-semibold mb-[1.6rem]"
              >
                {t("StarCompany.block1.p1")}
              </motion.p>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-semibold mb-[1.6rem]"
              >
                {t("StarCompany.block1.p2")}
              </motion.p>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-semibold mb-[1.6rem]"
              >
                {t("StarCompany.block1.p3")}
              </motion.p>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-semibold"
              >
                {t("StarCompany.block1.p4")}
              </motion.p>
            </motion.div>
          </div>
        </div>
      </section>
      <section className={`bg-[#486e40]`}>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="relative w-full max-w-[1200px] text-white leading-7">
            <img
              className="absolute object-cover hidden left-[10%] top-[50%] pl-[2.5rem] -translate-x-1/2 -translate-y-1/2 w-[33%] h-full mainPageLG:block"
              alt="transperent-logo"
              src={Bg1}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:ml-[30%]"
            >
              <motion.h5
                variants={mainAnimation}
                className="text-center md:text-left mb-[1.6rem]"
              >
                {t("StarCompany.block2.ul")}
              </motion.h5>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li4")}
                </motion.li>
                <motion.li
                  custom={5}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li5")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
    

      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-12"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="text-center md:text-left mb-[1.6rem]"
              >
                {t("StarCompany.block3.p1")}
              </motion.h5>
              <motion.p
                variants={mainAnimation}
                className="mb-[0.5rem] font-semibold"
              >
                {t("StarCompany.block3.ul")}
              </motion.p>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("StarCompany.block2.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[1.6rem]"
                >
                  {t("StarCompany.block2.li4")}
                </motion.li>
              </motion.ul>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-semibold"
              >
                {t("StarCompany.block3.p2")}
              </motion.p>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <Footer mainColor="#486e40" info={footerInfo} />
      </section>
    </main>
  );
};
