import { motion } from "framer-motion";
import Logo from "../../images/Logos/Pulse.png";
import HeaderLogo from "../../images/Header/Pulse.png";
import Bg1 from "../../images/BrandsBackground/pulse.jpg";
import mainPhoto from "../../images/BrandsBackground/pulse-main.jpg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import { useTranslation } from "react-i18next";
import {
  arrAnimation,
  branchAnimationLeft,
  branchAnimationRight,
  mainAnimation,
} from "../../animations";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";

export const PulseMarketing = () => {
  const footerInfo = {
    formURL: "",
    phoneNumber: "+38 (098)-037-61-51",
    email: "newswork1488@gmail.com",
    inst: "",
    telega: "",
    address: "",
    viber: "0980376151",
  };
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const isDesktop = screenWidth > 1100;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <main>
      <Header mainColor={`#65a147`} imgSrc={HeaderLogo} branchRoute="/a-tech" />
      <section className="relative h-[75vh]">
        <div
          className="w-full h-full absolute top-0 left-0"
          style={{
            backgroundImage: `url(${mainPhoto})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="container__standart pt-[25vh] flex items-center flex-col"
          >
            <motion.h1
              variants={mainAnimation}
              className="text-center text-white font-bold text-5xl mx-[2rem] mainPageLG:text-6xl"
            >
              {t("PulseMarketing.name")}
            </motion.h1>
            <button
              className={`transition__hover mt-[6.25rem] bg-[#65a147] text-white px-[10px] h-12 rounded-lg hover:bg-orange-400`}
              onClick={() => {
                dispatch(setActive(true));
              }}
            >
              {t("mainPage.consultation")}
            </button>
          </motion.div>
        </div>
      </section>
      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-24 mainPageLG:max-w-[500px]"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-bold mb-[1.6rem] text-center md:text-left"
              >
                {t("PulseMarketing.block1.h1")}
              </motion.h5>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="mb-[1.6rem] font-semibold"
              >
                {t("PulseMarketing.block1.p1")}
              </motion.p>
            </motion.div>
          </div>
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:ml-[30%]"
            >
              <motion.h5
                variants={branchAnimationRight}
                className="font-bold mb-[1.6rem] text-center md:text-left"
              >
                {t("PulseMarketing.block1.h2")}
              </motion.h5>
              <motion.ul
                variants={branchAnimationRight}
                className="list-disc ml-[2.5rem] font-semibold"
              >
                <li className="mb-[0.5rem]">
                  {t("PulseMarketing.block1.li1")}
                </li>
                <li className="mb-[0.5rem]">
                  {t("PulseMarketing.block1.li2")}
                </li>
                <li className="mb-[0.5rem]">
                  {t("PulseMarketing.block1.li3")}
                </li>
                <li className="mb-[0.5rem]">
                  {t("PulseMarketing.block1.li4")}
                </li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section className={`bg-[#65a147]`}>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="relative w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="absolute hidden left-[90%] top-[50%] pr-[2.5rem] -translate-x-1/2 -translate-y-1/2 w-[45%] h-full mainPageLG:block"
              alt="transperent-logo"
              src={Bg1}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[40%]"
            >
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="mb-[1.6rem] font-bold text-2xl"
              >
                {t("PulseMarketing.block3.p1")}
              </motion.p>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li31")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li32")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li33")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li34")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-24 mainPageLG:max-w-[500px]"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-bold mb-[1.6rem] text-center md:text-left"
              >
                {t("PulseMarketing.block3.h1")}
              </motion.h5>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li2")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:ml-[30%]"
            >
              <motion.h5
                variants={branchAnimationRight}
                className="font-bold mb-[1.6rem] text-center md:md:text-right"
              >
                {t("PulseMarketing.block3.h2")}
              </motion.h5>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li21")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li22")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="font-bold mb-[1.6rem] text-center md:text-left"
              >
                {t("PulseMarketing.block2.h1")}
              </motion.h5>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li4")}
                </motion.li>
                <motion.li
                  custom={5}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li5")}
                </motion.li>
                <motion.li
                  custom={6}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li6")}
                </motion.li>
                <motion.li
                  custom={6}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block2.li7")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-24 mainPageLG:max-w-[500px]"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:ml-[30%]"
            >
              <motion.h5
                variants={branchAnimationRight}
                className="font-bold mb-[1.6rem] text-center md:text-right"
              >
                {t("PulseMarketing.block3.h3")}
              </motion.h5>
              <motion.ul className="list-disc ml-[2.5rem] font-semibold">
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li41")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li42")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li43")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("PulseMarketing.block3.li44")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <Footer mainColor="#65a147" info={footerInfo} />
      </section>
    </main>
  );
};
