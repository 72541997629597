import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { screenWidthState } from "../../types/SliceTypes/widthState";

const initialState: screenWidthState = {
  siteWidth: window.innerWidth,
};

const screenWidthSlice = createSlice({
  name: "screenWidth",

  initialState,

  reducers: {
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.siteWidth = action.payload;
    },
  },
});

export const { setScreenWidth } = screenWidthSlice.actions;
export default screenWidthSlice.reducer;
